<app-header></app-header>
<div *ngFor="let act of acts" [attr.id]="act.href">
    <a></a>
    <div class="actInfoShort row" [ngClass]="{'actInfoShortHideBorder': act.showLongActInfo,
                                              'roundLeftTop': act.id % 2 === 0, 
                                              'roundRightTop': act.id % 2 === 1,
                                              'roundLeftBottom' : act.id % 2 === 1 && !act.showLongActInfo,
                                              'roundRightBottom' : act.id % 2 === 0 && !act.showLongActInfo}">

        <!--picture-->
            <div class="col-12 col-md-5 projectImg order-sm-first" [ngClass]="{'order-md-last': act.id % 2 === 0}"
                [ngStyle]="{'background-image': 'url(' + act.image + ')', 'cursor' : act.hideLeesVerder ? 'auto' : 'pointer'}" 
                title="{{act.imageText}}" (click)="showActExtraInfo(act)">
            </div>

        <!-- tekst part -->
        <div class="col-12 col-md-7 m-0 p-0 order-md-1 order-12">
            <h1>{{act.title}}</h1>
            <p class="text" [innerHTML]="act.info">{{act.info}}</p>

            <!-- lees verder button -->
            <div class="col-12 leesVerder" [hidden]="act.hideLeesVerder || act.showLongActInfo">
                <p style="text-align:center;">
                    <span (click)="showActExtraInfo(act)"> <span i18n [hidden]="act.showLongActInfo">meer info</span>
                        <div style="margin-top: -15px; margin-bottom: -9px;">
                            <br>
                        </div>
                    </span>
                </p>
            </div>
        </div>
    </div>

    <div [hidden]="!act.showLongActInfo" class="actInfoLong row"
        [ngStyle]="{'border-bottom-right-radius' : act.id % 2 === 0 ? '10px' : '0px',
                                                         'border-bottom-left-radius'  : act.id % 2 === 0 ? '0px' : '10px'}">
        <div class="col-12">
            <p class="moreText" [innerHTML]="act.longActInfo">{{act.longActInfo}}</p>
        </div>
        <div class="col-12 p-0 m-0 text-muted">
            <div [hidden]="act.hideSlideshow" class="slideshow"
                [ngClass]="{'slideshowRadiusRight': act.id % 2 === 0, 
                                                                                'slideshowRadiusLeft': act.id % 2 === 1}">
                <ng-image-slider #nav [images]="act.slideshowArray" [infinite]="true" [imagePopup]="true"
                    [showArrow]="true" [videoAutoPlay]="true"
                    [imageSize]="{ height: '155px', width: 'auto', space: 5 }">
                </ng-image-slider>
            </div>
        </div>
    </div>
</div>