<br/>
  <h1>Partners</h1>
  <div class="marquee">
    <div class="marquee-content">
      <!--Repeat READ CSS COMMENT IF ADDING LOGO-->
      <!-- logo size is 250 x 150 px-->
      <ng-container *ngFor="let _ of [].constructor(3)">
      <a href="https://www.omroepbrabant.nl/nieuws/2986250/naar-de-kapper-in-de-achtbaan-en-dansen-in-een-draaiende-discotheek-het-kan-allemaal-op-paaspop" target="_blank"><img src="./assets/partners/Paaspop.webp" alt="Paaspop"></a>

      <a href="https://www.ed.nl/helmond/nieuwe-bewoners-arie-willemshof-in-helmond-uit-de-tent-gelokt-met-pletfoto-op-kopieermachine~aca93410/" target="_blank"><img src="./assets/partners/Gemeente Helmond.webp" alt="Gemeente Helmond"></a>

      <a href="https://soundcloud.com/fontyslive/de-turbo-touw-tornado" target="_blank"><img src="./assets/partners/Mundial.webp" alt="Mundial"></a>
      
      <a href="https://www.daisyfestival.nl/" target="_blank"><img src="./assets/partners/Daisy.webp" alt="Daisy"></a>

      <a href="https://www.deschoenfabriek.nl/" target="_blank"><img src="./assets/partners/DeSchoenfabriek.webp" alt="De Schoenfabriek"></a>
      
      <a href="https://theaterspeelhuis.nl/" target="_blank"><img src="./assets/partners/Speelhuis.webp" alt="Speelhuis"></a>
      
      <img src="./assets/partners/Nemo.webp" alt="Nemo">

      <a href="https://buskersbern.ch/" target="_blank"><img src="./assets/partners/BuskersBurns.webp" alt="Buskers Burns"></a>
      
      <a href="https://opheteiland.nl/" target="_blank"><img src="./assets/partners/Festival Op Het Eiland.webp" alt="Festival Op Het Eiland"></a>
      
      <a href="https://zwartecross.nl/" target="_blank"><img src="./assets/partners/ZwarteCross.webp" alt="Zwarte Cross"></a>
      
      <a href="https://www.concertatsea.nl/" target="_blank"><img src="./assets/partners/Concert at Sea.webp" alt="Concert at Sea"></a>
      
      <a href="https://www.theateraantwater.be/" target="_blank"><img src="./assets/partners/Theater Aan Twater.webp" alt="Theater Aan Twater"></a>

      <a href="https://https://www.olala.at/" target="_blank"><img src="./assets/partners/Olala.webp" alt="Olala Lienz"></a>
      
      <a href="https://solarweekend.com/" target="_blank"><img src="./assets/partners/Solar.webp" alt="Solar"></a>
      
      <a href="https://www.freshtival.nl/" target="_blank"><img src="./assets/partners/Freshtival.webp" alt="Freshtival"></a>

      <a href="https://www.wishoutdoor.com/" target="_blank"><img src="./assets/partners/Wish Outdoor.webp" alt="Wish Outdoor"></a>

      <a href="https://www.carolinafestival.nl/" target="_blank"><img src="./assets/partners/CarolinaFestival.webp" alt="Carolina Festival"></a>
      </ng-container>
      <!--READ CSS COMMENT IF ADDING LOGO-->
    </div>
  </div>